import { useContext } from "react";
import styled from "styled-components";
import { MAIN_PADDING_2, BREAKPOINTS } from "../constants";
import { KeyboardArrowRight } from "@styled-icons/material";
import CustomCarousel from "./carousel";
import Image from 'next/image'

import LanguageContext from "../contexts/Language";

const HeroWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 0px;
  overflow: hidden;
`;

const HeroContentWrapper = styled.div`
  background-color: ${(props) => props.bg || "#2c315a"};
  background-size: cover;
`;

const HeroContent = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 1160px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${BREAKPOINTS.MD}) {
    padding: 0 20px;
  }
`;

const HeroTextBlock = styled.div`
  position: relative;
  width: auto;
  height: auto;
  max-width: 500px;
  z-index: 1;
`;

const BubbleImageBlock = styled.div`
  max-width: 40%;
  position: relative;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  height: 70%;
  padding: 20px;
  box-sizing: border-box;

  @media (max-width: ${BREAKPOINTS.SM}) {
    display: none;
  }
`;

const BubbleImage = styled(Image)`
  position: relative;
  width: auto;
  height: 80%;
  z-index: 1;
`;

const Button = styled.button`
  background-color: #3586ff;
  border: none;
  color: white;
  font-size: 16px;
  padding: 12px 12px;
  border-radius: 10px;
  text-align: center;
  position: relative;
  padding-left: 35px;
  padding-right: 30px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
`;

const ButtonArrow = styled(KeyboardArrowRight)`
  position: absolute;
  width: 20px;
  left: 10px;
`;

const Heading = styled.h1`
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 30px;
`;

const SubHeading = styled.h3`
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 30px;
`;

const CarouselWrapper = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: ${MAIN_PADDING_2} @media (max-width: ${BREAKPOINTS.MD}) {
    margin-bottom: 0;
  }
`;

export default function HeroBlock(props) {
  const lang = useContext(LanguageContext);

  return (
    <HeroWrapper>
      <CarouselWrapper>
        <CustomCarousel dotsColor="white" autoplay={true}>
          {props.heroItems.map((heroItem, index) => (
            <HeroContentWrapper
              key={index}
              bg={heroItem.backgroundColor}
              css={{ backgroundImage: `url('${heroItem.backgroundImage}');` }}
            >
              <HeroWrapper>
                <HeroContent>
                  <HeroTextBlock>
                    <Heading>{heroItem.heading}</Heading>
                    {heroItem.subheading ? (
                      <SubHeading>{heroItem.subheading}</SubHeading>
                    ) : (
                      ""
                    )}
                    <a href={heroItem.link && heroItem.link.url}>
                      <Button>
                        <ButtonArrow />
                        {heroItem.buttonText}
                      </Button>
                    </a>
                  </HeroTextBlock>
                  <BubbleImageBlock>
                    <BubbleImage src={heroItem.image} alt="Friendly Hero image" layout={'fill'}></BubbleImage>
                  </BubbleImageBlock>
                </HeroContent>
              </HeroWrapper>
            </HeroContentWrapper>
          ))}
        </CustomCarousel>
      </CarouselWrapper>
    </HeroWrapper>
  );
}
