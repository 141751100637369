import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import fetch from 'isomorphic-unfetch'
import PropTypes from 'prop-types'

import { getUrlWithLocale } from '../utils/url'
import translations from '../config/translations'
import { withRedux } from '../lib/redux'
import { NextSeo } from 'next-seo'
import Layout from '../components/layout'
import HeroBlock from '../components/hero-block'
import BlogBlock from '../components/blog-block'
import PartnersBlock from '../components/partners-block'

import ProductFilter from '../components/product-filter'
import BigProductCards from '../components/big-product-cards'
import Testimonials from '../components/testimonials'
import ProductSlider from '../components/product-slider'
import general from '../config/general'
import fetchProps from '../helpers/fetch-props'

const getProductURL = ({ categorySlug, limit = 12 }, locale) => {
	let productsUrl = `${general.API_URL}/wp-json/api/v2/products/${locale}?limit=${limit}&page=1`

	if (categorySlug) {
		productsUrl += `&category-slug=${categorySlug}`
	}

	return productsUrl
}

const Index = (props) => {
	const { locale } = useRouter()

	const [activeProducts, setActiveProducts] = useState([])
	const [newProducts, setNewProducts] = useState([])
	const [activeCategorySlug, setActiveCategorySlug] = useState(null)

	const fetchAndSetProducts = async () => {
		if (!locale) {
			return;
		}

		const productURL = activeCategorySlug ? getProductURL({ categorySlug: activeCategorySlug }, locale) : getProductURL({}, locale) + '&random=true'

		let newProducts = await (await fetch(getUrlWithLocale(productURL, locale))).json()

		// Difference in APIs
		newProducts = newProducts.products || newProducts

		if (newProducts) {
			setActiveProducts(newProducts)
		}
	}

	const fetchPopularProducts = async () => {
		const LIMIT = 10;
		const categorySlug = {
			en: 'new',
			et: 'uued-tooted'
		};
		const newProductsURL = getProductURL({ categorySlug: categorySlug[locale], limit: LIMIT }, locale) + `&random=true`;
		const newProducts = await (await fetch(newProductsURL)).json()


		if (newProducts?.products && newProducts.products.length) {
			setNewProducts(newProducts.products)
		}
	}

	const onCategoryClick = (categorySlug) => {
		setActiveCategorySlug(categorySlug)
	}

	const getCategoriesBlockData = () => {
		if (!props?.optionsData?.categories_block) {
			return []
		}

		return props.optionsData.categories_block.map((currentItem) => {
			if (!currentItem[`label_${locale}`] || !currentItem[`page_link_${locale}`]) {
				return;
			}

			return {
				icon: currentItem?.icon[0],
				label: currentItem[`label_${locale}`],
				pageLink: currentItem[`page_link_${locale}`],
			}
		});
	}

	useEffect(() => {
		fetchAndSetProducts({
			page: 1,
			categorySlug: activeCategorySlug,
		})
	}, [activeCategorySlug, locale])

	useEffect(() => {
		fetchPopularProducts()
	}, [])

	const categoriesBlockData = getCategoriesBlockData()?.filter(Boolean);

	return (
		<Layout footerData={props.footerData} productCategoriesData={props.productCategoriesData} locale={locale}>
			<NextSeo title={translations['Promotional Gifts, Promotional Items & Business Gifts'][locale]} />
			<HeroBlock heroItems={props.slidersData} />
			<ProductFilter
				categories={props.productCategoriesData}
				products={activeProducts}
				onCategoryClick={onCategoryClick}
				simple={true}
				hideMobile={true}
				moreLink={{
					href: `/products`,
					params: {
						category: activeCategorySlug,
					},
				}}
			/>
			{categoriesBlockData?.length ? <BigProductCards data={categoriesBlockData} /> : null}
			<ProductSlider title={translations['New products'][locale]} products={newProducts} />
			{props.testimonialsData?.length ? <Testimonials testimonialsData={props.testimonialsData} /> : null}
			<BlogBlock blog={props.blogData} isHomePage={true} />
			<PartnersBlock partners={props.partnersData} />
		</Layout>
	)
}

Index.propTypes = {
	optionsData: PropTypes.shape({
		categories_block: PropTypes.arrayOf(PropTypes.shape({
			icon: PropTypes.array,
			label_en: PropTypes.string,
			label_et: PropTypes.string,
			page_link_en: PropTypes.string,
			page_link_et: PropTypes.string
		}))
	}),
	footerData: PropTypes.object,
	productCategoriesData: PropTypes.array,
	slidersData: PropTypes.array,
	testimonialsData: PropTypes.array,
	blogData: PropTypes.array,
	partnersData: PropTypes.array,
	locale: PropTypes.string
}

export const getServerSideProps = async function ({ locale }) {
	try {
		const [
			productCategoriesData,
			footerData,
			partnersData,
			slidersData,
			testimonialsData,
			optionsData
		] = await Promise.all([
			fetchProps.productCategoriesData(locale),
			fetchProps.footerData(locale),
			fetch(`${general.API_URL}/wp-json/api/v2/partners/${locale}`).then(res => res.json()).catch(() => []),
			fetch(`${general.API_URL}/wp-json/api/v2/sliders/${locale}`).then(res => res.json()).catch(() => []),
			fetch(`${general.API_URL}/wp-json/api/v2/testimonials/${locale}`).then(res => res.json()).catch(() => []),
			fetch(`${general.API_URL}/wp-json/api/v2/options`).then(res => res.json()).catch(() => [])
		]);

		// Handle blog category and posts separately due to conditional logic
		let blogData = [];
		try {
			let blogCategoryURL = `${general.API_URL}/wp-json/wp/v2/categories?slug=blog`;
			if (locale !== 'en') {
				blogCategoryURL = blogCategoryURL + '_' + locale;
			}
			const blogCategory = await fetch(blogCategoryURL).then(res => res.json());
			
			if (blogCategory && blogCategory[0]?.id) {
				blogData = await fetch(getUrlWithLocale(`${general.API_URL}/wp-json/wp/v2/posts?categories=${blogCategory[0].id}`, locale))
					.then(res => res.json())
					.catch(() => []);
			}
		} catch (error) {
			console.error('Error fetching blog data:', error);
		}

		return {
			props: {
				optionsData,
				productCategoriesData: productCategoriesData?.filter((cat) => ![319, 318, 317].includes(cat.id)) || [],
				footerData: footerData || {},
				partnersData: partnersData || [],
				slidersData: slidersData || [],
				testimonialsData: testimonialsData || [],
				blogData: blogData || [],
				locale,
			},
		}
	} catch (error) {
		console.error('Error in getServerSideProps:', error);
		return {
			props: {
				optionsData: {},
				productCategoriesData: [],
				footerData: {},
				partnersData: [],
				slidersData: [],
				testimonialsData: [],
				blogData: [],
				locale,
			},
		}
	}
}

export default withRedux(Index)
