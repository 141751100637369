import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CustomCarousel from "./carousel";
import {
  MAIN_PADDING,
  MAX_BLOCK_WIDTH,
  MAIN_COLOR,
  BREAKPOINTS,
} from "../constants";
import isMobile from "../hooks/is-mobile";

const PartnersWrapper = styled.div`
  width: 100%;
  max-width: ${MAX_BLOCK_WIDTH};
  background-color: #f8f9ff;
  padding: ${MAIN_PADDING};
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  box-sizing: border-box;
  justify-content: center;
  position: relative;
`;

const PartnersInner = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 0px;
  overflow-y: hidden;
`;

const ImageWrapper = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
`;

const HeroImage = styled.img`
  min-width: 100px;
  min-height: 100px;
  max-width: 100%;
  max-height: 100%;
  object-fit: scale-down;
  margin-left: auto;
  margin-right: auto;
`;

const DotsWrapper = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  left: 0;
  bottom: -80px;
`;

const Partner = styled.div`
  padding-bottom: 50px;
`;

const PartnersRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 35px;
  max-width: 75%;
  justify-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${BREAKPOINTS.MD}) {
    grid-template-columns: 1fr;
  }
`;

export default function HeroBlock(props) {
  const mobile = isMobile();

  const getArrayChunks = (list, chunkSize) => {
    let R = [];
    let theList = list && list.length ? [...list] : [];

    for (let i = 0; i < theList.length; i += chunkSize) {
      R.push(theList.slice(i, i + chunkSize));
    }

    return R;
  };

  const getPartnersRow = (partnersBlock) => {
    return partnersBlock.map((partner) => {
      return (
        <Partner key={partner.id}>
          <ImageWrapper>
            <a href={partner.link}>
              <HeroImage src={partner.imageUrl} />
            </a>
          </ImageWrapper>
        </Partner>
      );
    });
  };

  return (
    <PartnersWrapper>
      <PartnersInner>
        <CustomCarousel autoplay={true}>
          {getArrayChunks(props.partners, mobile ? 1 : 3).map(
            (partnerBlock, index) => (
              <div key={index}>
                <PartnersRow> {getPartnersRow(partnerBlock)} </PartnersRow>
              </div>
            )
          )}
        </CustomCarousel>
      </PartnersInner>
    </PartnersWrapper>
  );
}
