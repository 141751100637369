import React from 'react'
import styled from 'styled-components'
import CustomCarousel from './carousel'
import { MAIN_PADDING, MAX_BLOCK_WIDTH, MAIN_COLOR, BREAKPOINTS } from '../constants'
// import SVGLogo from '../public/logo.svg';

const Testimonials = styled.div`
	width: 100%;
	max-width: ${MAX_BLOCK_WIDTH};
	background-color: #f8f9ff;
	padding: ${MAIN_PADDING};
	margin-bottom: 100px;
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	box-sizing: border-box;
	justify-content: center;
	position: relative;

	@media (max-width: ${BREAKPOINTS.MD}) {
		margin-bottom: 40px;
	}
`

const TestimonialsInner = styled.div`
	width: 100%;
	height: auto;
	margin-bottom: 0px;
	overflow-y: hidden;
`

const Testimonial = styled.div`
	width: 600px;
	height: auto;
	min-height: 200px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	font-size: 14px;

	&:before {
		content: '';
		position: absolute;
		width: 20px;
		height: 20px;
		top: 0px;
		left: -40px;
		background-image: url('../left-quote.svg');
		background-size: contain;
		background-repeat: no-repeat;
	}

	@media (max-width: ${BREAKPOINTS.MD}) {
		width: 100%;
	}
`

const TestimonialText = styled.p`
	width: 100%;
	height: auto;
	margin-bottom: 20px;
	margin-top: 0;
	text-align: left;
`

const TestimonialCredit = styled.span`
	width: 100%;
	height: auto;
	text-align: right;
`

export default function HeroBlock(props) {
	const responsive = [
		{ breakPoint: 1280, cardsToShow: 1 },
		{ breakPoint: 760, cardsToShow: 1 },
		{ breakPoint: 375, cardsToShow: 1 }
	]

	return (
		<Testimonials>
			<TestimonialsInner>
				<CustomCarousel autoplay={true}>
					{props.testimonialsData.map((item, index) => (
						<div key={index}>
							<Testimonial>
								<TestimonialText>“{item.testimonial}“</TestimonialText>
								<TestimonialCredit>
									{item.company_link ? (
										<a href={item.company_link} target="_blank">- {item.author}, {item.company}</a>
									) : (
										<>
											- {item.author}, {item.company}
										</>
									)}
								</TestimonialCredit>
							</Testimonial>
						</div>
					))}
				</CustomCarousel>
			</TestimonialsInner>
		</Testimonials>
	)
}
