import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import CustomCarousel from "./carousel";
import {
  MAIN_PADDING,
  MAX_BLOCK_WIDTH,
  MAIN_COLOR,
  MAX_CONTENT_WIDTH,
  MAIN_PADDING_2,
  BREAKPOINTS,
} from "../constants";

import useWindowDimensions from "../hooks/use-window-dimensions";

import ProductCard from "./product-filter/product-card";
import translations from "../config/translations";
import LanguageContext from "../contexts/Language";

const PartnersWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  max-width: ${MAX_CONTENT_WIDTH};
  margin: ${MAIN_PADDING_2} auto;
  height: auto;

  @media (max-width: ${BREAKPOINTS.MD}) {
    margin: ${MAIN_PADDING} auto;
  }
`;

const PartnersInner = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 0px;
  overflow-y: hidden;
  padding: 35px;
`;

const ImageWrapper = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  background-color: #000;
  border-radius: 10px;
`;

const HeroImage = styled.img`
  min-width: 100px;
  min-height: 100px;
  max-width: 100%;
  max-height: 100%;
  object-fit: scale-down;
  margin-left: auto;
  margin-right: auto;
`;

const DotsWrapper = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  left: 0;
  bottom: -80px;
`;

const Partner = styled.div`
  padding-bottom: 50px;
`;

const PartnersRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-column-gap: 35px;
  width: 100%;
  justify-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${BREAKPOINTS.MD}) {
    grid-template-columns: 1fr 1fr;
  }
`;

const ProductSliderHeader = styled.h3`
  color: ${MAIN_COLOR};
  padding: 0px ${MAIN_PADDING};
  margin-bottom: 35px;
  font-size: 26px;
  font-weight: 200;
  text-align: left;
`;

export default function ProductSlider(props) {
  const lang = useContext(LanguageContext);
  const { width } = useWindowDimensions();
  const responsive = [
    { breakPoint: 1280, cardsToShow: 5 },
    { breakPoint: 760, cardsToShow: 2 },
    { breakPoint: 375, cardsToShow: 2 },
    { breakPoint: 0, cardsToShow: 1 },
  ];
  const [cardsToShow, setCardsToShow] = useState(4);

  const getArrayChunks = (list, chunkSize) => {
    let R = [];
    let theList = list && list.length ? [...list] : [];

    for (let i = 0; i < theList.length; i += chunkSize) {
      R.push(theList.slice(i, i + chunkSize));
    }

    return R;
  };

  const getPartnersRow = (products) => {
    return products.map((product, index) => {
      return (
        <Partner key={`${index}-partners-row-${product?.id}`}>
          <ProductCard product={product} />
        </Partner>
      );
    });
  };

  useEffect(() => {
    const breakPoint = responsive.reduce((acc, cur) => {
      if (width >= cur.breakPoint && acc < cur.breakPoint) {
        acc = cur.breakPoint;
      }

      return acc;
    }, 0);

    const { cardsToShow: newCardsToShow } =
      breakPoint && responsive.find((r) => r.breakPoint === breakPoint);

    setCardsToShow(newCardsToShow);
  }, [width]);

  return (
    <PartnersWrapper>
      <ProductSliderHeader>{props.title}</ProductSliderHeader>

      <PartnersInner>
        <CustomCarousel autoplay={true}>
          {getArrayChunks(props.products, cardsToShow || 2).map(
            (productsChunk, index) => (
              <div key={`carousel-${index}`}>
                <PartnersRow> {getPartnersRow(productsChunk)} </PartnersRow>
              </div>
            )
          )}
        </CustomCarousel>

        <div
          style={{
            textAlign: "right",
            width: "100%",
          }}
        >
          {props.button}
        </div>
      </PartnersInner>
    </PartnersWrapper>
  );
}
