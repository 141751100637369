import React from 'react';
import styled from 'styled-components';
import { Carousel } from 'antd';
import { MAIN_PADDING, MAX_BLOCK_WIDTH, MAIN_COLOR } from '../constants';

const StyledCarousel = styled(Carousel)`
  .slick-dots.slick-dots.slick-dots > * > button {
    opacity: 0.4;
    background-color: ${props => props.dotsColor || MAIN_COLOR};
  }

  .slick-dots.slick-dots.slick-dots > .slick-active > button {
    opacity: 1;
  }
`;

export default function CustomCarousel({ children, autoplay, dotsColor}) {
  return (
    <StyledCarousel autoplay={autoplay} dotsColor={dotsColor || null} autoplaySpeed={3000}>
      { children }
    </StyledCarousel>
  );
};
