import React, { useContext } from 'react'
import styled from 'styled-components'
import Link from 'next/link'

import translations from '../config/translations'
import LanguageContext from '../contexts/Language'

import { MAIN_PADDING, MAIN_PADDING_2, MAX_CONTENT_WIDTH, MAIN_COLOR, BREAKPOINTS } from '../constants'

import { Leaf } from '@styled-icons/fa-solid'
import { NewReleases } from '@styled-icons/material'
import { Paint } from '@styled-icons/boxicons-regular'
import { Percentage } from '@styled-icons/fa-solid'
import { StarFullOutline } from 'styled-icons/typicons'

import CustomLink from './custom-link'

const CardIcon = styled.span`
	width: 50px;
	height: 50px;
	display: inline-block;

	& > * {
		width: 100%;
		height: 100%;
		color: ${MAIN_COLOR};
	}
`

const Wrapper = styled.div`
	width: 100%;
	max-width: ${MAX_CONTENT_WIDTH};
	margin: ${MAIN_PADDING_2} auto;
	height: auto;
	display: flex;
	justify-content: center;

	@media (max-width: ${BREAKPOINTS.MD}) {
		display: flex;
		justify-content: center;
		text-align: center;
		flex-wrap: wrap;
		margin: ${MAIN_PADDING} auto;
	}
`

const BigProductCard = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	transition: 0.3s ease all;
	overflow: hidden;
	align-items: center;
	flex: 0 1 20%;

	&:before,
	&:after {
		content: '';
		position: absolute;
		width: 100%;
	}

	&:after {
		height: 100%;
		top: 0;
		left: 0;
		background-size: cover;
		background-position: center;
		background-image: url(${(props) => (props.bgImage ? props.bgImage : '')});
		transition: 0.3s ease all;
		z-index: 1;
	}

	&:hover {
		cursor: pointer;

		&:after {
			transform: scale(1.1);
		}
	}

	@media (max-width: ${BREAKPOINTS.MD}) {
		flex: 0 1 50%;
	}
`

const Content = styled.div`
	position: relative;
	flex: 0 1 100%;
	color: #fff;
	padding: 25px ${MAIN_PADDING};
	bottom: 0;
	z-index: 3;
	text-align: center;
`

const Title = styled.h4`
	font-size: 18px;
	font-weight: 300;
	margin-bottom: 10px;
	margin-top: 15px;
	color: ${MAIN_COLOR};
`

const Description = styled.p`
	margin-top: 0;
	margin-bottom: 0;
`

export default function BigProductCards({ data }) {
	const lang = useContext(LanguageContext)
	const bgImage = 'https://www.elisa.ee/files/square-banners/2019-12/elisa_reetur_koduleht_960x960.jpg'

	const getIcon = (icon) => {
		const iconSelection = {
			star: <StarFullOutline />,
			percentage: <Percentage />,
			paintbrush: <Paint />,
			exclamation: <NewReleases />,
			leaf: <Leaf />
		}

		return iconSelection[icon] || <StarFullOutline />
	}

	return (
		<Wrapper>
			{data.map(({ icon, label, pageLink }) => (
				<BigProductCard>
					<Content>
						<CustomLink href={pageLink}>
							<CardIcon>{getIcon(icon)}</CardIcon>
						</CustomLink>
						<CustomLink href={pageLink}>
							<Title>{label}</Title>
						</CustomLink>
					</Content>
				</BigProductCard>
			))}
		</Wrapper>
	)
}
